<template>
  <AppointmentCalendar />
</template>

<script>
import AppointmentCalendar from './appointment-calendar/AppointmentCalendar'

export default {
  name: 'AppointmentCreate',
  components: { AppointmentCalendar }
}
</script>

<style scoped>

</style>